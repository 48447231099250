import React from 'react'
import classNames from 'classnames'


import './accessoriesChoice.module.css'

const AccessoriesChoice = (props) => (
  <div styleName={classNames('choice', {'active': props.currentChoice === props.label})} onClick={() => props.onClick(props.label)}>
    <img styleName={'image'} src={props.imgSrc} alt={props.imgAlt} />
    <div styleName={'label'}>{props.en ? props.enDict[props.label] : props.label}</div>
  </div>
);

export default AccessoriesChoice;
