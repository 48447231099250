import React from 'react'
import AccessoriesChoice from './AccessoriesChoice'
import AccessoriesSubChoice from './AccessoriesSubChoice'
import './accessoriesPicker.module.css';
import Produkt from '../Produkt/Produkt';


class AccessoriesPicker extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      currentChoice: '',
      currentSubChoice: '',
    };
  }

  onChoiceClick = (choiceName) => {
    this.setState(() => ({
      currentChoice: choiceName,
      currentSubChoice: '',
    }));
  };

  onSubChoiceClick = (subChoiceName) => {
    this.setState(() => ({
      currentSubChoice: subChoiceName,
    }));
  };

  sortSubChoices = (a, b) => {
  	if (a.slice(0,1) === 'F') return -1;
    if (b.slice(0,1) === 'F') return 1;
    return a.length - b.length;
  }

  sortMenuContent = () => {
    return this.props.menuContent.subMenu[this.state.currentChoice].sort(this.sortSubChoices);
  }

  sortByPrice = (a, b) => {
    if (a.props.price < 0) return 1;
    if (b.props.price < 0) return -1;
    return a.props.price - b.props.price;
  };

  render() {
    const filtered = this.props.displayContent.filter(element => (
      element.subCategory === this.state.currentSubChoice && element.category === this.state.currentChoice
    ));

    const products = filtered.map(e => (
      <Produkt
        name={e.name}
        specs={e.specifications.json}
        material={e.material}
        color={e.color}
        image={e.images}
        weight={e.weight}
        price={e.price}
        key={e.name}
        stock={e.lagernd}
      />
  )).sort(this.sortByPrice);

    return (
      <div styleName={'picker'}>
        <div styleName={'choicesContainer'}>
          {Object.keys(this.props.menuContent.mainMenu).map(key => (
            <AccessoriesChoice
              label={key}
              onClick={this.onChoiceClick}
              currentChoice={this.state.currentChoice}
              imgSrc={this.props.menuContent.mainMenu[key].imgURL}
              imgAlt={key}
              key={key}
              en={this.props.en}
              enDict={this.props.enDict}
            />
        ))}
        </div>
        {this.state.currentChoice &&
        <div styleName={'subChoicesContainer'}>
          {this.sortMenuContent().map(choice => (
            <AccessoriesSubChoice
              key={choice}
              onClick={this.onSubChoiceClick}
              currentSubChoice={this.state.currentSubChoice}
              label={choice}
              en={this.props.en}
              enDict={this.props.enDict}
            />
          ))}
        </div>}
        <hr/>
        <div styleName={'contentDisplay'}>
          {(this.state.currentChoice && this.state.currentSubChoice) ?
            <div>
            <div styleName={'contentTitle'}>
              {this.props.en ? `${this.props.enDict[this.state.currentChoice]} - ${this.props.enDict[this.state.currentSubChoice]}` : `${this.state.currentChoice} - ${this.state.currentSubChoice}`}
            </div>
            {products}
            </div>
            :
            <div>{this.props.placeholderText}</div>
          }
        </div>
      </div>
    )
  }
}

export default AccessoriesPicker;
