import React from 'react'
//import classNames from 'classnames'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Location } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import PriceTag from '../PriceTag/PriceTag';

import './produkt.module.css'

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <div>{children}</div>,
  },
};

const Produkt = (props) => (
  <Location>
    {({ location }) => {
      const onENRoute = (location.pathname.indexOf('/en/') !== -1);
      const i = onENRoute ? 0 : 1;

      return (
        <StaticQuery
          query={productLabelsQuery}
          render={data => {
            const labels = data.allContentfulProductLabels.nodes[i];
            return (
              <div styleName={'produktContainer'}>
                <div styleName={'name'}>
                  {props.name}
                </div>
                <div styleName={'detailsContainer'}>
                  <div styleName={'imageContainer'}>

                    {/*console.log(props.image)*/}
                    {props.image != null &&

                      props.image.map(e => {return <img src={`https://${e.file.url}`} alt={'produktBild'} key={e.file.url}/>;})[0]}

                    {/*<img src={`https://${props.image}`} alt={'produktBild'} key={props.image}/>*/}
                  </div>
                  <table styleName={'productInfoContainer'}>
                    <tbody>
                      <tr>
                        <td styleName={'firstCol'}>
                          <strong>{labels.weightLabel}</strong>
                        </td>
                        <td>
                          {props.weight} kg
                        </td>
                      </tr>
                      <tr>
                        <td styleName={'firstCol'}>
                        <strong>{labels.materialLabel}</strong>
                        </td>
                        <td>
                          {props.material}
                        </td>
                      </tr>
                      <tr>
                        <td styleName={'firstCol'}>
                          <strong>{labels.colorLabel}</strong>
                        </td>
                        <td>
                          {props.color}
                        </td>
                      </tr>
                      <tr>
                        <td styleName={'firstCol'}>
                          <strong>{labels.specsLabel}</strong>
                        </td>
                        <td>
                          {documentToReactComponents(props.specs, options)}
                        </td>
                      </tr>
                      <tr>
                        <td styleName={'firstCol'}>
                            <strong>{labels.priceLabel}</strong>
                        </td>
                        <td>
                          {props.price > 0 ? <PriceTag price={props.price} /> : <i>{labels.preisAufNachfrageLabel}</i>}
                        </td>
                      </tr>
                      {/*props.stock &&
                        <React.Fragment>
                          <tr/><tr/>
                          <tr>
                            <td styleName={'firstCol'}></td>
                            <td>
                              <i>{labels.stockLabelPositive}</i>
                            </td>
                          </tr>
                        </React.Fragment>
                      */}
                    </tbody>
                  </table>
                </div>
              </div>
          )}}
        />
      )
    }}
  </Location>
)

export default Produkt;

const productLabelsQuery = graphql `
  query productLabelsQuery {
    allContentfulProductLabels {
      nodes {
        colorLabel
        priceLabel
        specsLabel
        stockLabelPositive
        weightLabel
        materialLabel
        preisAufNachfrageLabel
      }
    }
  }
`
