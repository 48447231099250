import React from 'react'
import { Location } from "@reach/router"
//import classNames from 'classnames'

import './pricetag.module.css';

const formatPrice = (price, isENPath) => {
  var formatedPrice = ""
  if (isENPath) {
    // 1,500
    formatedPrice = `${price.toLocaleString('en-US')}.00`
  } else {
    // 1.500
    formatedPrice = `${price.toLocaleString('DE')},00`
  }
  return `€ ${formatedPrice}`;
}

const PriceTag = (props) => (
  <Location>
    {({ location }) => {
      const path = location.pathname;
      const onENpath = (path.indexOf("/en/") !== -1);
      return (
        <span styleName={'priceTag'}>
          {formatPrice(props.price, onENpath)}
        </span>
      )
  }}
  </Location>
)

export default PriceTag
