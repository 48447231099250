import React from 'react'
import classNames from 'classnames'


import './accessoriesSubChoice.module.css'

const AccessoriesChoice = (props) => (
  <div styleName={classNames('subchoice', {'active': props.currentSubChoice === props.label})} onClick={() => props.onClick(props.label)}>
    <div styleName={'label'}>{props.en ? props.enDict[props.label] : props.label}</div>
    <div styleName={'spacer'} />
  </div>
);

export default AccessoriesChoice;
