import { graphql } from 'gatsby';
export default from './zubehoer';


export const accessoryQuery = graphql `
  query accessoryENQuery {
    accessories: allContentfulAccessory(filter: {node_locale: {eq: "en" }}) {
      nodes {
        ...AccessoryQueryFragment
      }
    }
    accessoryPageLabels: contentfulAccessorypage(node_locale: {eq: "en"}) {
      ...AccessoryPageQueryFragment
    }
    schuelerViolineImg: contentfulAsset(file: {fileName: {eq: "geige.png"}}) {
      ...URLFragment
    }
    schuelerCelloImg: contentfulAsset(file: {fileName: {eq: "cello.png"}}) {
      ...URLFragment
    }
    schuelerBassImg: contentfulAsset(file: {fileName: {eq: "bass.png"}}) {
      ...URLFragment
    }
  }
`
