import React from 'react'
import Page from '../components/Page/Page'
import AccessoriesPicker from '../components/AccessoriesPicker/AccessoriesPicker'
import { graphql } from 'gatsby'

import { Location } from '@reach/router';

const ZubehoerPage = ({data}) => {
  const enDict = {
    'Etui': 'Case',
    'Tasche': 'Bag',
    'Formetui': 'Form Shaped Case',
    'Kofferetui': 'Oblong Case',
    'Etuis für 2 Violinen': 'Oblong Case for 2 Violins',
    'Viola': 'Viola',
    'Violine': 'Violin',
    'Cello': 'Cello',
    'Bass': 'Bass'
  };

  const accessoryCategories = data.accessories.nodes.reduce((acc, it) => {
    const instrumentCategory = it.category;
    const subCategory = it.subCategory;
    if (!acc[instrumentCategory]) {
      acc[instrumentCategory] = [];
    }
    if (acc[instrumentCategory].indexOf(subCategory) === -1) {
      acc[instrumentCategory].push(subCategory);
    }
    return acc;
  }, {});

  return (
    <Location>
      {({ location }) => {
        const onENRoute = (location.pathname.indexOf('/en/') !== -1);

        const contentDict = {
          'Violine': {
            'imgURL': `https://${data.schuelerViolineImg.file.url}`,
          },
          'Viola': {
            'imgURL': `https://${data.schuelerViolineImg.file.url}`,
          },
          'Cello': {
            'imgURL': `https://${data.schuelerCelloImg.file.url}`,
          },
          'Bass': {
            'imgURL': `https://${data.schuelerBassImg.file.url}`,
          }
        };

        const menuContent = {mainMenu: contentDict, subMenu: accessoryCategories};

        return (
          <Page
            title={data.accessoryPageLabels.title}
            renderCallback={() => (
                <AccessoriesPicker
                  menuContent={menuContent}
                  displayContent={data.accessories.nodes}
                  placeholderText={data.accessoryPageLabels.selectionPlaceholder}
                  en={onENRoute}
                  enDict={enDict}
                />
            )}
          />
      )}}
    </Location>
  )
}

export default ZubehoerPage

export const accessoryQueryFragment = graphql `
  fragment AccessoryQueryFragment on ContentfulAccessory {
    category
    subCategory
    color
    material
    lagernd
    name
    price
    specifications {
      json
    }
    weight
    images {
      description
      file {
        url
      }
    }
  }
`

export const accessoryPageQueryFragment = graphql `
  fragment AccessoryPageQueryFragment on ContentfulAccessorypage {
    title
    selectionPlaceholder
  }
`

export const accessoryQuery = graphql `
  query accessoryDEQuery {
    accessories: allContentfulAccessory(filter: {node_locale: {eq: "de" }}) {
      nodes {
        ...AccessoryQueryFragment
      }
    }
    accessoryPageLabels: contentfulAccessorypage(node_locale: {eq: "de"}) {
      ...AccessoryPageQueryFragment
    }
    schuelerViolineImg: contentfulAsset(file: {fileName: {eq: "geige.png"}}) {
      ...URLFragment
    }
    schuelerCelloImg: contentfulAsset(file: {fileName: {eq: "cello.png"}}) {
      ...URLFragment
    }
    schuelerBassImg: contentfulAsset(file: {fileName: {eq: "bass.png"}}) {
      ...URLFragment
    }
  }
`
